<template>
  <admin>
    <metatag title="Dealers"></metatag>

    <!-- redirect to upload form -->
    <page-header>
      <template v-slot:action>
        <v-btn
          :to="{ name: 'inquiries.upload' }"
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="info"
          exact
        >
          <v-icon small left>mdi-cloud-upload</v-icon>
          <span v-text="'Upload'"></span>
        </v-btn>
      </template>
    </page-header>

    <!-- data table -->
    <v-row>
      <v-col>
        <v-card>
          <!-- table toolbar -->
          <toolbar-menu
            ref="toolbar"
            :items.sync="tabletoolbar"
            :filter-status="true"
            :filter-action="true"
            @update:search="setSearch"
            @update:action="setAction"
            @update:status="setStatus"
          ></toolbar-menu>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="resources.headers"
              :items="resources.data"
              :loading="resources.loading"
              :items-per-page="5"
              :server-items-length="resources.total"
              :options.sync="resources.options"
              color="primary"
              item-key="id"
              class="text-no-wrap"
            >
              <!-- :search="filterData.search" -->
              <template v-slot:progress><span></span></template>

              <template v-slot:loading>
                <v-slide-y-transition mode="out-in">
                  <skeleton-avatar-table></skeleton-avatar-table>
                </v-slide-y-transition>
              </template>

              <!-- item.cust_number eto yung header ng table na need lagyan value -->
              <!-- customer number -->
              <template v-slot:item.cust_number="{ item }">
                {{ item.cust_number }}
              </template>
              <!-- customer details -->
              <template v-slot:item.name="{ item }">
                {{ item.title }} {{ item.first_name }} {{ item.last_name }} <br />
                <small>{{ item.mobile }}</small> <br />
                <small>{{ item.email }}</small>
              </template>
              <!-- customer address -->
              <template v-slot:item.address="{ item }">
                <small>
                  {{ item.street }} ,{{ item.barangay }} {{ item.municipality }},
                  {{ item.province }}
                  {{ item.zipcode }}
                </small>
              </template>
              <!-- inquiry date -->
              <template v-slot:item.inquiry_date="{ item }">
                {{ formatDate(item.inquiry_date) }}
              </template>
              <!-- vehicle info -->
              <template v-slot:item.vehicle="{ item }">
                <small>{{ item.model_name }}</small> <br />
                <small>{{ item.variant_code }}</small> <br />
                <small>{{ item.color_name }}</small>
              </template>
              <!-- sales consultant -->
              <template v-slot:item.sales_consultant="{ item }">
                {{ item.assigned_sc_user ? item.assigned_sc_user.first_name : "" }}
                {{ item.assigned_sc_user ? item.assigned_sc_user.last_name : null }}
              </template>
              <!-- sc assigned date -->
              <template v-slot:item.sc_assigned_date="{ item }">
                {{ item.sc_assigned_at ? formatDate(item.sc_assigned_at) : "" }}
              </template>
              <!-- status of inquiry -->
              <template v-slot:item.status="{ item }">
                <v-chip label>
                  {{ item.status ? item.status.label : "" }}
                </v-chip>
              </template>
              <!-- action -->
              <template v-slot:item.action="{ item }">
                <v-chip label>
                  {{ item.action ? item.action.label : "" }}
                </v-chip>
              </template>
              <!-- date updated -->
              <template v-slot:item.date_updated="{ item }">
                {{ item.updated_at ? formatDate(item.updated_at) : "" }}
              </template>

              <template v-slot:item.id="{ item }">
                <div class="text-no-wrap">
                  <!-- Preview -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="previewItem(item)" color="primary" text icon v-on="on">
                        {{ item.id }}
                      </v-btn>
                    </template>
                    <span v-text="'Preview'"></span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:item.editAction="{ item }">
                <can :code="['dealer', 'sales_consultant']">
                  <!-- Edit -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editItem(item)" text icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Edit'"></span>
                  </v-tooltip>
                  <!-- Edit -->
                </can>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- edit dialog -->
    <can :code="code">
      <v-dialog
        v-model="inquiryDialog"
        max-width="600px"
        class="overflow-hidden"
      >
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Edit" class="mb-1">Edit Customer #{{ editedItem.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-row>
              <!-- -->
              <v-col cols="12">
                <SalesConsultant :assigned_sc_user_id="this.editedItem.assigned_sc_user_id" />
              </v-col>
            </v-row>
            <!-- status -->
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.status"
                  append-icon="mdi-chevron-down"
                  :items="resources.statusOptions"
                  item-text="title"
                  item-value="slug"
                  label="Status"
                  outlined
                  clearable
                  hide-details
                  clear-icon="mdi-close-circle-outline"
                  background-color="selects"
                ></v-select>
              </v-col>
            </v-row>
            <!-- action -->
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.action"
                  append-icon="mdi-chevron-down"
                  :items="resources.actionOptions"
                  item-text="title"
                  item-value="slug"
                  label="Action"
                  outlined
                  clearable
                  hide-details
                  clear-icon="mdi-close-circle-outline"
                  background-color="selects"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="inquiryDialog = false"
            >
              Cancel
            </v-btn>

            <v-btn :loading="submitLoading" :disabled="submitLoading" large exact color="green darken-1" class="ma-1 white--text px-5" @click="update">
              <v-icon left>mdi-content-save</v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </can>

    <!-- preview dialog -->
    <v-dialog v-model="previewDialog" max-width="900px" class="overflow-hidden">
      <v-card class="pa-4">
        <v-card-title>
          <h2 title="Edit" class="mb-1">#{{ resources.previewData.id }}</h2>
        </v-card-title>
        <v-card-text class="overflow-y-auto">
          <!-- Background Details -->
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ "Customer" }}</td>
                  <td>
                    {{ resources.previewData.title }} {{ resources.previewData.first_name }}
                    {{ resources.previewData.last_name }}
                  </td>
                  <td class="font-weight-bold">{{ "Date of Birth" }}</td>
                  <td>{{ resources.previewData.birthdate }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Province" }}</td>
                  <td>{{ resources.previewData.province }}</td>
                  <td class="font-weight-bold">{{ "Municipality" }}</td>
                  <td>{{ resources.previewData.municipality }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Barangay" }}</td>
                  <td>{{ resources.previewData.barangay }}</td>
                  <td class="font-weight-bold">{{ "Zip Code" }}</td>
                  <td>{{ resources.previewData.zipcode }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Street" }}</td>
                  <td>{{ resources.previewData.street }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Email" }}</td>
                  <td>{{ resources.previewData.email }}</td>
                  <td class="font-weight-bold">{{ "Mobile" }}</td>
                  <td>{{ resources.previewData.mobile }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Model" }}</td>
                  <td>{{ resources.previewData.model_name }}</td>
                  <td class="font-weight-bold">{{ "Variant" }}</td>
                  <td>{{ resources.previewData.variant_code }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Color" }}</td>
                  <td>{{ resources.previewData.color_name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Inquiry Date" }}</td>
                  <td>{{ formatDate(resources.previewData.inquiry_date) }}</td>
                  <td class="font-weight-bold">{{ "Sales Consultant" }}</td>
                  <td>
                    {{
                      resources.previewData.assigned_sc_user
                        ? resources.previewData.assigned_sc_user.first_name
                        : ""
                    }}
                    {{
                      resources.previewData.assigned_sc_user
                        ? resources.previewData.assigned_sc_user.last_name
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Status" }}</td>
                  <td>
                    <v-chip label>
                      {{ resources.previewData.status ? resources.previewData.status.label : "" }}
                    </v-chip>
                  </td>
                  <td class="font-weight-bold">{{ "Action" }}</td>
                  <td>
                    <v-chip label>
                      {{ resources.previewData.action ? resources.previewData.action.label : "" }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Assigned Date" }}</td>
                  <td>
                    {{
                      resources.previewData.sc_assigned_at
                        ? formatDate(resources.previewData.sc_assigned_at)
                        : ""
                    }}
                  </td>
                  <td class="font-weight-bold">{{ "Date Created" }}</td>
                  <td>{{ formatDate(resources.previewData.created_at) }}</td>
                </tr>
                <!-- <tr>
                  <td class="font-weight-bold">{{ "Status" }}</td>
                  <td>
                    <v-chip color="soft-success" text-color="success">
                      {{ 'status' }}
                    </v-chip>
                  </td>
                </tr> -->
                <tr>
                  <td class="font-weight-bold">{{ "Date Updated" }}</td>
                  <td>
                    {{
                      resources.previewData.updated_at
                        ? formatDate(resources.previewData.updated_at)
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Dealer" }}
                  </td>
                  <td>{{ resources.previewData.dealer_code }}</td>
                  <td class="font-weight-bold">
                    {{ "Preferred Communication" }}
                  </td>
                  <td>{{ resources.previewData.preferred_communication }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- Background Details -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="grey"
            exact
            class="ma-1 white--text px-5"
            @click="previewDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin>
</template>

<script>
import apiModuleType from "@/services/api/modules/moduleTypeService";
import SalesConsultant from "@/components/SalesConsultant/SalesConsultant";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      inquiryDialog: false,
      previewDialog: false,
      submitLoading: false,
      code: ['dealer'],
      tabletoolbar: {
        isSearching: false,
        search: null,
        type: "inquiry",
      },
      resources: {
        loading: true,
        headers: [
          {
            text: "ID",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "Cust. #",
            align: "left",
            value: "customer_id",
            class: "text-no-wrap",
          },
          { text: "Name", align: "left", value: "name", class: "text-no-wrap" },
          {
            text: "Address",
            align: "left",
            value: "address",
            class: "text-no-wrap",
          },
          {
            text: "Dealer",
            value: "dealer_code",
            class: "text-no-wrap",
          },
          {
            text: "Inquiry Date",
            value: "inquiry_date",
            class: "text-no-wrap",
          },
          { text: "Vehicle", value: "vehicle", class: "text-no-wrap" },
          {
            text: "Sales Consultant",
            value: "sales_consultant",
            class: "text-no-wrap",
          },
          {
            text: "SC Assigned Date",
            value: "sc_assigned_date",
            class: "text-no-wrap",
          },
          { text: "Status", value: "status", class: "text-no-wrap" },
          { text: "Action", value: "action", class: "text-no-wrap" },
          {
            text: "Date Updated",
            value: "date_updated",
            class: "text-no-wrap",
          },
        ],
        options: {},
        data: [],
        previewData: [],
        statusOptions: [],
        actionOptions: [],
      },
      editedItem: {
        id: null,
        assigned_sc_user_id: null,
        sc_assigned_at: "",
        status: null,
        action: null,
      },
      filterData: {
        status: "",
        action: "",
        q: "",
      },
    };
  },
  components: {
    SalesConsultant,
  },
  computed: {
    ...mapGetters({
      data: "inquiries/GET_INQUIRIES",
      salesConsultant: "report/GET_SALES_CONSULTANT",
      userRoles: "auth/GET_ROLES",
    }),
  },
  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        this.getItems(page, itemsPerPage, this.filterData);
      },
      deep: true,
    },
  },
  methods: {
    formatDate(item, format) {
      return helpers.format_date(item, format);
    },
    ...mapActions({
      getInquiries: "inquiries/getInquiries",
      updateInquiry: "inquiries/updateInquiry",
      setSalesConsultant: "report/setSalesConsultant",
      getUserType: "auth/getUserType",
    }),
    // child actions
    setStatus: debounce(async function (value) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      // set data prop to check if the value is not null when getting all records by status
      this.filterData.status = value;
      this.getItems(1, itemsPerPage, this.filterData);
    }, 300),
    // child actions
    setAction: debounce(async function (value) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      // set data prop to check if the value is not null when getting all records by action
      this.filterData.action = value;
      await this.getItems(1, itemsPerPage, this.filterData);
    }, 300),
    // function to search
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filterData.q = e.target.value;
      this.getItems(1, itemsPerPage, this.filterData);
    }, 300),

    // get list of inquiries function
    getItems(page, itemsPerPage, filterData = {}) {
      this.resources.loading = true;
      this.getInquiries({
        page: page,
        per_page: itemsPerPage,
        filters: filterData,
      }).then((data) => {
        this.resources.data = this.data.data;
        this.resources.total = this.data.meta.total;
        this.resources.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },
    // get status api to fetch data in edit modal
    async getStatusList() {
      const { status, data } = await apiModuleType.getStatusList("inquiry");
      if (status === 200) {
        this.resources.statusOptions = data.data;
      }
    },
    // get action api to fetch data in edit modal
    async getActionList() {
      const { status, data } = await apiModuleType.getActionList("inquiry");
      if (status === 200) {
        this.resources.actionOptions = data.data;
      }
    },

    showTip(e) {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },
    previewItem(item) {
      this.previewDialog = true;
      this.resources.previewData = item;
    },
    editItem(item) {
      // check sc if assigned
      this.editedItem.assigned_sc_user_id = item.assigned_sc_user ? item.assigned_sc_user.id : 0;
      this.editedItem.sc_assigned_at = item.sc_assigned_at ? item.sc_assigned_at : "";
      this.editedItem.status = item.status ? item.status.code : "";
      this.editedItem.action = item.action ? item.action.code : "";
      this.editedItem.id = item.id;
      this.inquiryDialog = true;
    },
    async update() {
      this.editedItem.assigned_sc_user_id = this.salesConsultant;
      this.submitLoading = true;

      this.updateInquiry(this.editedItem).then((data) => {
        this.submitLoading = false;
        if (data.success === true) {
          const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
          this.getItems(page, itemsPerPage, this.filterData);
          this.inquiryDialog = false;
        }
      });
    },
  },
  async mounted() {
    await this.getStatusList();
    await this.getActionList();
    await this.getUserType();

    let user_roles = this.userRoles.map(a => a.slug);

    if (user_roles.includes("dealer") || user_roles.includes("sales_consultant")) {
      this.resources.headers = [
        ...this.resources.headers,
        {
          text: "Actions",
          align: "center",
          value: "editAction",
          class: "muted--text text-no-wrap",
        },
      ];
    }
  },
};
</script>

<style></style>
